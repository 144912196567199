const ml_billing_plans = {
	free: {
		events: 10_000,
		model_calculations: 5_000,
		data_storage_days: 30,
		
		price_monthly: 0.00,
		price_annually: 0.00,
		
		price_extra_10000_events: 3.00,
		price_extra_1000_model_calculations: 3.00,
	},
	
	standard_legacy: {
		events: 1_000_000,
		model_calculations: 50_000,
		data_storage_days: 60,
		
		price_monthly: 139.00,
		price_annually: 99.00 * 12,
		
		price_extra_10000_events: 3.00,
		price_extra_1000_model_calculations: 3.00,
	},
	
	basic: {
		events: 100_000,
		model_calculations: 10_000,
		data_storage_days: 60,
		
		price_monthly: 139.00,
		price_annually: 99.00 * 12,
		
		price_extra_10000_events: 3.00,
		price_extra_1000_model_calculations: 3.00,
	},
	
	standard: {
		events: 1_000_000,
		model_calculations: 100_000,
		data_storage_days: 90,
		
		price_monthly: 499.00,
		price_annually: 399.00 * 12,
		
		price_extra_10000_events: 3.00,
		price_extra_1000_model_calculations: 3.00,
	},
	
	// Example, update to reflect actual enterprise plan limits
	enterprise_1: {
		events: 10_000_000,
		model_calculations: 1_000_000,
		data_storage_days: 90,
		
		price_monthly: 2000.00,
		price_annually: 1670.00 * 12,
		
		price_extra_10000_events: 2.00,
		price_extra_1000_model_calculations: 2.00,
	},
	
	enterprise_2400: {
		events: 10_000_000,
		model_calculations: 1_000_000,
		data_storage_days: 190,
		
		price_monthly: 2400.00,
		price_annually: 2000.00 * 12,
		
		price_extra_10000_events: 2.00,
		price_extra_1000_model_calculations: 2.00,
	},
}

export const useBillingPlans = () => {
	return { ml: ml_billing_plans }
}

export function getBillingPlanDisplayName(plan: string)
{
	switch (plan)
	{
		case 'free':
			return 'Free Plan'
		case 'basic':
			return 'Basic Plan'
		case 'standard':
			return 'Standard Plan'
		case 'standard_legacy':
		case 'standard-legacy':
			return 'Standard Plan (Legacy)'
		
		case 'basic-monthly':
			return 'Basic Plan (billed monthly)'
		case 'basic-annually':
			return 'Basic Plan (billed annually)'
		case 'standard-monthly':
			return 'Standard Plan (billed monthly)'
		case 'standard-annually':
			return 'Standard Plan (billed annually)'
		
		case 'enterprise_1':
		case 'enterprise_2400':
			return 'Enterprise Plan'
		
		default:
			return plan
	}
}